.top-right-modal {
  position: absolute;
  top: 0;
  right: 11px;
}

.e-control-wrapper.e-slider-container.e-horizontal .e-slider-track {
  background: #007bff;
  height: 3px;
}

/* .e-scale .e-tick.e-custom::before {
  content: "\e967";
  position: absolute;
} */

.e-control-wrapper.e-slider-container .e-slider .e-handle {
  background-color: #007bff;
  border-radius: 50%;
  border: 0;
}

.e-control-wrapper.e-slider-container .e-slider .e-range {
  background-color: #e5f3ff;
}

.btn-filter {
  min-width: 160px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.4em 0.7em;
  text-align: center;
}

.btn-filter:hover {
  color: var(--primary-color);
  background-color: #fff;
  font-weight: bold;
  border-color: var(--primary-color);
}

.btn-reset {
  min-width: 160px;
  padding: 0.4em 0.7em;
  text-align: center;
  background-color: #e5f3ff;
  border-color: #e5f3ff;
  color: var(--text-primary-color);
}

.btn-reset:hover {
  background-color: #e5f3ff;
  border-color: #e5f3ff;
  color: var(--text-primary-color);
  font-weight: bold;
}

.filter-title {
  display: inline-block;
  font-weight: bold;
}

/* To specify height and font size */
.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  background-color: #e5f3ff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* To specify background color, color, and border */
.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  border-color: var(--primary-color);
}

/* .e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  border: 1px solid;
} */

/* To specify background color and color  */
.e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

/* To specify background color, color, and border-color  */
.e-calendar .e-btn.e-today.e-flat.e-primary,
.e-calendar .e-css.e-btn.e-today.e-flat.e-primary {
  background-color: var(--primary-color);
  color: #fff;
}

/* To specify background color and font size */
.e-input-group .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  color: var(--text-primary-color);
  background-color: #e5f3ff;
  margin: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(
    .e-float-icon-left
  ),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
  .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error)
  .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
  .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error)
  .e-input-in-wrap {
  border: none;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input.e-input,
.e-float-input input,
.e-float-input.e-control-wrapper input.e-input,
.e-float-input.e-control-wrapper input,
.e-input-group input.e-input:focus,
.e-input-group.e-control-wrapper input.e-input:focus,
.e-float-input.e-control-wrapper input:focus,
.e-float-input input:focus,
.e-input-group.e-input-focus input.e-input,
.e-input-group.e-control-wrapper.e-input-focus input.e-input,
.e-float-input.e-control-wrapper.e-input-focus input,
.e-float-input.e-input-focus input {
  padding: 0.375rem 0.75rem;
}
/* 
.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus:focus {
  border: 0 !important;
} */

/* To specify background color and border */
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: var(--text-primary-color);
}

.form-check-label {
  text-transform: capitalize;
}
