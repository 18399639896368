.spinner-container {
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.9px);
  -webkit-backdrop-filter: blur(12.9px);
}

.loader {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-mask: linear-gradient(0deg, #000 55%, #0000 0) bottom/100% 18.18%;
  background: linear-gradient(var(--primary-color) 0 0) bottom/100% 0% no-repeat
    #ddd;
  animation: l8 2s infinite steps(7);
}
@keyframes l8 {
  100% {
    background-size: 100% 115%;
  }
}
