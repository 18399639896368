.custom-icon-details {
  background-image: url("../../../assets/images/Icons/view.svg");
  width: 28px !important;
  height: 28px !important;
  background-position: center;
  background-size: cover;
}

.custom-icon-edit {
  background-image: url("../../../assets/images/Icons/edit.svg");
  width: 28px !important;
  height: 28px !important;
  background-position: center;
  background-size: cover;
}

.custom-icon-delete {
  background-image: url("../../../assets/images/Icons/delete.svg");
  width: 28px !important;
  height: 28px !important;
  background-position: center;
  background-size: cover;
}
.custom-icon-more {
  background-image: url("../../../assets/images/Icons/more.svg");
  width: 28px !important;
  height: 28px !important;
  background-position: center;
  background-size: cover;
}

.e-grid {
  border: 0;
}

.e-grid .e-gridheader tr th {
  padding: 0;
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.e-grid .e-gridheader {
  border: 0;
}

.e-grid .e-gridpager {
  border: 0;
}

.e-pagesizes {
  visibility: hidden;
}

.e-headercontent {
  border: 0 !important;
}

.e-content {
  height: max-content !important;
}

.e-grid td.e-cellselectionbackground {
  background: #9ac5ee;
  font-style: italic;
}

.e-grid .e-gridpager .e-currentitem,
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover,
.e-pager .e-currentitem.e-focused {
  background-color: var(--primary-color) !important;
  color: #fff;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection {
  background-color: var(--primary-color) !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

.e-grid.e-wrap .e-rowcell {
  font-size: 0.9rem;
  padding: 0.35em 0.7em !important;
}

.e-grid.e-wrap .e-columnheader .e-stackedheadercelldiv,
.e-grid.e-wrap .e-columnheader .e-headercelldiv,
.e-grid.e-wrap .e-columnheader .e-headercell.e-fltr-icon .e-headercelldiv {
  font-size: 0.98rem;
}

.e-pager {
  border: none !important;
}

.e-grid .e-detailindentcell {
  border-top-style: solid;
  border-top-width: 1px;
  border-right: 0;
}

.status-badge {
  padding: 0.25em;
  margin: 0 0.5em;
  text-align: center;
  position: relative;
}

.inquery-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #d68e01;
}
.default-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #31343a5e;
}
.processing-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #0263ff;
}
.under-review-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #0263ff;
}
.ready-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #ffaa04;
}

.new-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #0263ff;
}

.data-extracted-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #ff7723;
}
.ai-approved-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #219653;
}
.approved-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #219653;
}
.rejected-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #ff001f;
}

.reviewed-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #6c57f2;
}

.failed-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #ff001f;
}

.successful-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #219653;
}

.review-required-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #8e30ff;
}
.approval-required-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #319dff;
}
.ready-for-processing-badge::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  border-radius: 5px;
  background: #ff7723;
}

.filter-badge {
  color: var(--text-primary-color);
  background-color: var(--bg-main);
}

.invoice-badge::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #8e30ff;
}

.credit-note-badge::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #0263ff;
}

.sample-invoice-badge::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #ff7723;
}

.resubmit-btn {
  border: 2px solid var(--secondary-color);
  font-size: 0.8rem !important;
  color: #002765;
}

.resubmit-btn:hover {
  color: #fff;
  background-color: #002765;
  /* border: 2px solid var(--secondary-color); */
}

.assignTo-btn {
  border: 2px solid #ff7723;
  font-size: 0.8rem !important;
  color: #ff7723;
}

.assignTo-btn:hover {
  color: #000;
  background-color: #ff7723;
  /* border: 2px solid var(--secondary-color); */
}

.manuallyEntered-btn {
  border: 2px solid #6c57f2;
  font-size: 0.8rem !important;
  color: #6c57f2;
}

.manuallyEntered-btn:hover {
  color: #fff;
  background-color: #6c57f2;
}

.inQuery-btn {
  border: 2px solid #d68e01;
  font-size: 0.8rem !important;
  color: #d68e01;
}

.inQuery-btn:hover {
  color: #fff;
  background-color: #d68e01;
}
