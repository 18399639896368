.invoice-manager {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

/* .invoice-manager.failed,
.invoice-manager.inquery {
  grid-template-rows: auto;
} */

.stats {
  grid-area: "stats";
}
.content {
  grid-area: "content";
  width: 100%;
  overflow: auto;
}

.invoice-container {
  overflow: auto;
  position: relative;
  height: 100%;
  width: 100%;
}

.btn-add-invoice {
  min-width: 160px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.4em 0.7em;
  text-align: center;
}

.btn-add-invoice:hover {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
}

.stat-title {
  margin-bottom: 0.2em;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.btn:disabled {
  border: none;
}
