.btn-approve {
  min-width: 160px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.4em 0.7em;
  text-align: center;
  border: 0;
}

.btn-approve:hover {
  background-color: #fff;
  color: var(--primary-color);
  font-weight: bold;
  border: 1px solid var(--primary-color);
}

.btn-reject {
  border: 0;
  min-width: 160px;
  background-color: #ff001f;
  color: #fff;
  padding: 0.4em 0.7em;
  text-align: center;
}

.btn-reject:hover {
  background-color: #fff;
  color: #ff001f;
  font-weight: bold;
  border: 1px solid #ff001f;
}

.reject-title {
  color: #ff001f;
}

.approve-title {
  color: #219653;
}
