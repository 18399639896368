.approval-requests {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.section-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-tab {
  font-weight: bold;
  color: var(--text-primary-color);
  border: 0;
  border-radius: 0;
}

.active-tab {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.active-tab.btn:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}

.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.accordian-navbar {
  position: sticky;
  top: 0px;
  z-index: 4;
  background-color: #fff;
  border: 0;
}

.details-container {
  background-color: var(--bg-main);
  padding: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
}

.item-count {
  display: inline-block;
  border: 1px solid #000;
  color: #000;
  border-radius: 50%;
  min-width: 23px;
  min-height: 23px;
  padding: 0em 0.3em;
  margin-left: 0.5em;
  font-size: 0.9rem;
}

.btn-tab.active-tab .item-count {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}
