.list-group-item {
  color: #3b4758;
  /* font-weight: 600; */
  text-wrap: wrap;
}

.list-group-item.active {
  background-color: var(--bg-main);
  border-color: #e5f3ff;
  color: var(--text-primary-color);
  font-weight: bold;
}

.role-text {
  font-size: 0.8rem;
}

.avatar-dropdown-container a {
  text-decoration: none;
  color: var(--text-primary-color);
}

.avatar-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-menu {
  width: auto;
}

.btn-user:hover {
  scale: 1.2;
}

.user-name:hover {
  scale: 1.01;
}

.user-details-container {
  background-color: var(--bg-main);
  padding: 0.5em;
  width: 100%;
}
