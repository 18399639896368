.approval-rules {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
}
.condition-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--text-primary-color);
  border-radius: 0.4em;
  padding: 0.5em 1.5em;
  width: 100%;
  min-height: 5em;
  font-size: 0.9rem;
}

.description {
  margin-left: 1.9em;
}

.condition-container > label {
  font-size: 0.9rem;
}

.condition-container > input {
  font-size: 0.9rem;
  margin: 0;
}

.condition-field {
  border: 0;
  font-weight: 600;
  margin: 0.4em 0;
}

.condition-field:focus {
  outline: none;
}
