.invoice-search {
  display: "inline-block";
  width: "auto";
  background-color: #e5f3ff;
  border: 0;
}

.invoice-search:focus {
  box-shadow: none;
  background-color: #e5f3ff;
}

.table-search .invoice-search {
  padding: 0;
  padding-left: 0.5em;
}

.search-icon-container {
  border: 0;
  background-color: #e5f3ff;
}

.table-search .search-icon-container {
  padding: 0em;
}

.table-search .btn-search {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.2em 0.5em;
  border: none !important;
  background-color: #2405f2;
}

.table-search .btn-search.close {
  border-radius: 0;
  margin-right: 0.1em;
  border: none !important;
  background-color: #3b475863;
}

.table-search .btn-search:hover,
.table-search .btn-search:active {
  border: none !important;
  background-color: #2405f2;
}

.table-search .btn-search.close:hover {
  border: none !important;
  background-color: #3b475863;
}
