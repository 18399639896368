.layout {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "main";
}

.layout.collapsed {
  grid-template-columns: 265px 1fr;
  grid-template-areas: "main main";
}

.sidebar {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  z-index: 5;
  transition: transform 0.3s ease-in-out;
}

.sidebar.collapsed {
  display: block;
  transform: translateX(0%);
}

.navbar {
  padding: 1em;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: navbar;
  display: flex;
  align-items: center;
}

.navbar-container {
  width: 100%;
}

.main {
  position: relative;
  grid-area: main;
  overflow-y: auto;
  padding: 0.5em;
  width: 100%;
}

.content-container {
  width: 100%;
  min-height: calc(100vh - 92px);
  background-color: var(--bg-sidebar);
  border-radius: 0.3em;
  padding: 1em;
}

.btn-collapse {
  padding: 1em;
  padding-right: 0em;
  border: 0;
  border-radius: 0.4em;
  background-color: transparent;
  cursor: pointer;
}

.sidebar-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 0.5em;
  justify-content: space-between;
}

.btn-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0em 0.4em 0.25em 0.4em;
  border-radius: 5px;
  border: 2px solid #586a84;
  background: #fff;
}

.btn-menu.desktop {
  display: none;
}

.btn-menu.mobile {
  top: 95.5% !important;
  right: 16px;
}

.btn-menu:hover {
  scale: 1.1;
  border-width: 1px;
}

.nav-brand > img {
  width: 120px;
}

.avatar-dropdown-container {
  margin: 1em 0;
}

.sidebar.collapsed .avatar-container {
  margin-right: 10px;
}

@media screen and (min-width: 769px) {
  .layout {
    grid-template-columns: 265px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "sidebar main";
  }

  .layout.collapsed {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "sidebar main";
  }

  .sidebar {
    width: 100%;
    position: relative;
    display: block;
    overflow: hidden;
    transition: width 100ms linear;
  }

  .sidebar.collapsed {
    width: 100%;
  }

  .btn-menu.mobile {
    display: none;
  }

  .sidebar .btn-menu {
    position: unset;
    display: block;
    width: 99%;
    margin-bottom: 0.25em;
    color: #586a84;
    background-color: var(--bg-main);
    border: 0;
    padding: 0.5em;
  }

  .sidebar .btn-menu .btn-menu-text {
    display: none;
  }

  .sidebar.collapsed .btn-menu {
    position: unset;
  }

  .sidebar.collapsed .btn-menu .btn-menu-text {
    display: inline-block;
  }

  .sidebar .btn-menu:hover {
    font-weight: bolder;
    scale: unset;
  }

  .sidebar .btn-menu-text {
    margin-right: 1em;
  }

  .sidebar .sidebar-container .nav-brand > img {
    width: 50px;
  }

  .sidebar.collapsed .sidebar-container .nav-brand > img {
    width: 120px;
    transition: width 100ms linear;
  }

  .sidebar .sidebar-container .nav-link {
    justify-content: center;
  }

  .sidebar.collapsed .sidebar-container .nav-link {
    justify-content: start;
  }

  .sidebar .sidebar-container .nav-text {
    display: none;
  }

  .sidebar .sidebar-container img.nav-icon {
    width: 28px !important;
  }

  .sidebar.collapsed .sidebar-container .nav-text {
    display: inline;
  }

  .btn-collapse.close {
    display: none;
  }

  .avatar-dropdown-container {
    margin: 1em auto;
  }
}
