.invoice-edit-title {
  font-size: 1.1rem;
  margin-bottom: 0.5em;
}
.step-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1em;
}

.step-title {
  font-weight: bold;
  color: #3b4758b7;
  font-size: 0.9rem;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #3b4758b7;
  color: white;
  border-radius: 50%;
  text-align: center;
  margin-right: 0.6em;
  font-size: 0.9rem;
}

.step {
  display: flex;
}

.step.active > .step-number {
  background-color: #219653;
  font-weight: bold;
}

.step.active > .step-title {
  color: #3b4758;
  font-weight: bolder;
}

.edit-title {
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.2em;
}

.cancel {
  background-color: #eef0f4;
}

.active-box {
  border: 2px solid #219653;
  border-radius: 4px;
}

input#invoiceDate {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.comment-section {
  padding: 0.1em;
  /* max-height: 70vh; */
  /* overflow: auto; */
}

.comment-description {
  text-wrap: wrap !important;
}

.collapse-btn {
  border: 1px solid #000 !important;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.collapse-btn.closed {
  transform: rotate(180deg);
}

.edit-client-btn-group:hover {
  cursor: pointer !important;
}

.edit-invoice-client-btn {
  background-color: var(--primary-color) !important;
  padding: 2px !important;
  width: 50px !important;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 3px;
}

.edit-invoice-client-btn:hover {
  background-color: var(--secondary-color) !important;
}

.cancel-icon {
  max-width: 100% !important;
  height: auto !important;
}

.download-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 1000;
  cursor: pointer;
  background-color: #2405f2;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.download-icon:hover {
  transform: scale(1.05);
  background-color: #2405f2;
}
