.stat-details-container {
  display: flex;
  padding: 0.5em;
  align-items: center;
  justify-content: space-between;
  font-weight: bolder;
  height: 100% !important;
}

.review-required-container {
  border: 1px solid #8e30ff;
  background-image: linear-gradient(#8d30ff33, #fff);
}
.approval-required-container {
  border: 1px solid #319dff;
  background-image: linear-gradient(#319bff27, #fff);
}
.ready-for-processing-container {
  border: 1px solid #ff7723;
  background-image: linear-gradient(#ff77232a, #fff);
}
.successful-container {
  border: 1px solid #219653;
  background-image: linear-gradient(#2196542a, #fff);
}
.failed-container {
  border: 1px solid #ff001f;
  background-image: linear-gradient(#ff001e31, #fff);
}
